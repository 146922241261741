import axios from "axios";

const appauth_key = "Rahuwanshi@2029";
let APL_LINK = "http://192.168.1.16:8000";
APL_LINK = "https://notepadbackend.chatgptnotepad.com";
let Website_URL = "https://www.chatgptnotepad.com/";
let local_server_link_website = APL_LINK + "/api/auth/v1/";

/**Website Panel */
const get_all_home_front = local_server_link_website + "get_blog_data_website/";
const seo_data_url = local_server_link_website + "get_seo_data_website/";
const get_blog_details_url =
  local_server_link_website + "blog_details_website/";
const addFeedback = local_server_link_website + "addFeedback";
/**Website  panel */

// Retrieving data
const server_post_data = async (url_for, form_data) => {
  // const headers = {
  //   "Content-Type": "application/json",
  // };

  if (form_data === null) {
    form_data = new FormData();
  }

  form_data.append("appauth_key", appauth_key);
  form_data.append("res_name", "10099");
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,

  /**Website  panel */
  get_all_home_front,
  seo_data_url,
  get_blog_details_url,
  addFeedback,
  /**Website  panel */
};
