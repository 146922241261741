import React, { useEffect, useState } from "react";
// import './App.css';
import NotepadNew from "./Component/NotepadNew.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Blog from "./Component/Blog";
import ViewBlogs from "./Component/ViewBlogs.js";
import AboutUs from "./Component/AboutUs.js";
import ContactUs from "./Component/ContactUs.js";
import Home from "./Component/Home.js";
import TextEditor from "./Component/TextEditor.js";
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "./Component/PrivacyPolicy.js";
import Shortcut from "./Component/Shortcut.js";
import TermsOfUse from "./Component/TermsOfUse.js";

import {
  server_post_data,
  seo_data_url,
} from "./ServiceConnection/serviceconnection.js";
function App() {
  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/blog") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name === "/AboutUs") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name === "/ContactUs") {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name.includes("blog/blog_detail")) {
      data_seo_link_final = pretty_function_name + "~@~3";
    }
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <NotepadNew />;
    } else if (page_number === "2") {
      data_seo_link_final = <ViewBlogs />;
    } else if (page_number === "3") {
      data_seo_link_final = <Blog />;
    } else if (page_number === "4") {
      data_seo_link_final = <AboutUs />;
    } else if (page_number === "5") {
      data_seo_link_final = <ContactUs />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    await server_post_data(seo_data_url, null)
      .then((Response) => {
        if (!Response.data.error) {
          setSEOloop(Response.data.message.seo_loop);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/text_editor" element={<NotepadNew />} />
          <Route path="/blog" element={<ViewBlogs />} />
          <Route path="/blog/blog_detail/:id" element={<Blog />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/TextEditorNew" element={<TextEditor />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          <Route path="/shortcut" element={<Shortcut />} />

          {SEOloop.map((data, index) => {
            const for_loop_come = match_and_return_seo_link_and_page(
              data.call_function_name,
              data.pretty_function_name,
              data.title_name,
              data.favicon
            );
            const stringArray = for_loop_come.split("~@~");

            return (
              <Route
                key={index}
                path={stringArray[0]}
                element={get_page_name(stringArray[1])}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
