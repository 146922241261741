import React from "react";
import Logo from "../assets/logo192.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="header">
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.3rem 5px",
              }}
            >
              <h5 className="logoHead" style={{ width: "fit-content" }}>
                <img src={Logo} alt="Chat GPT notepad" title="Notepad GPT" />
                <snap className="hideAfter510">ChatGPT Notepad</snap>
              </h5>
            </div>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link to="/" class="nav-link">
                  <div className="casesBtns">
                    <button>Back To Editor</button>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
