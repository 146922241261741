import React from "react";
import { Link } from "react-router-dom";
import "./css/footer.css";

const Footer = () => {
  return (
    <>
      <div className="Footercontent">
        <Link to="https://www.chatgptnotepad.com/">
          <h5>Chatgptnotepad.com</h5>
        </Link>
        <h5>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/privacy_policy"
          >
            {" "}
            Privacy Policy
          </Link>
        </h5>
        <p>Copyright © 2024. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
