import React, { useRef, useState, useEffect } from "react";
import "./css/Notepad.css";
import Logo from "../assets/logo192.png";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import { Link } from "react-router-dom";

function NotepadNew() {
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState(
    sessionStorage.getItem("editorContent") || ""
  );
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [wordCount, setWordCount] = useState(0);
  const [characterCount, setCharCount] = useState(0);
  const [selectedFont, setSelectedFont] = useState("Arial");
  const [selectedFontSize, setSelectedFontSize] = useState("12");

  const boldText = () => {
    document.execCommand("bold", false, null);
  };

  const copyText = () => {
    document.execCommand("copy", false, null);
  };

  const cutText = () => {
    document.execCommand("cut", false, null);
  };
  useEffect(() => {
    // Save editor content to session storage on component mount and when it changes
    sessionStorage.setItem("editorContent", editorContent);
  }, [editorContent]);
  const selectAll = () => {
    const editor = editorRef.current;
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(editor);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const sanitizedText = text.replace(/[*#]/g, ""); // Remove * and #
    document.execCommand("insertText", false, sanitizedText);
  };

  const saveState = () => {
    const editor = editorRef.current.innerHTML;
    const newHistory = history.slice(0, currentIndex + 1);
    setHistory([...newHistory, editor]);
    setCurrentIndex(currentIndex + 1);
  };

  const undo = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      editorRef.current.innerHTML = history[currentIndex - 1];
    }
  };

  const redo = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      editorRef.current.innerHTML = history[currentIndex + 1];
    }
  };

  const saveToFile = () => {
    const textToSave = editorRef.current.innerText;
    const blob = new Blob([textToSave], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "notepad.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const clearEditor = () => {
    editorRef.current.innerHTML = "";
    saveState(); // Save the state after clearing the editor
  };

  const toggleFullScreen = () => {
    const element = document.documentElement;
    const notepadContainer = document.querySelector(".notepad");
    const textArea = document.querySelector(".notepad textarea");

    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        if (notepadContainer) {
          notepadContainer.style.padding = "0";
          notepadContainer.style.margin = "0";
        }
        if (textArea) {
          textArea.style.margin = "0";
        }
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        if (notepadContainer) {
          notepadContainer.style.padding = "0px"; 
          notepadContainer.style.margin = "auto";
        }
        if (textArea) {
          textArea.style.margin = "10px"; // Reset the margin when exiting full-screen
        }
      }
    }
  };

  const countWords = () => {
    const text = editorRef.current.innerText;
    const words = text.trim().split(/\s+/);
    setWordCount(words.length);
  };

  const countCharacters = () => {
    const text = editorRef.current.innerText;
    setCharCount(text.length);
  };

  useEffect(() => {
    countCharacters();
    countWords();
  }, [history]);

  const handleFontChange = (e) => {
    setSelectedFont(e.target.value);
    editorRef.current.style.fontFamily = e.target.value;
  };

  const handleFontSizeChange = (e) => {
    const pixelFontSize = e.target.value;
    let htmlFontSize;

    switch (pixelFontSize) {
      case "12":
        htmlFontSize = "3"; // Corresponds to HTML font size value for 12px
        break;
      case "14":
        htmlFontSize = "4"; // Corresponds to HTML font size value for 14px
        break;
      case "16":
        htmlFontSize = "5"; // Corresponds to HTML font size value for 16px
        break;
      case "18":
        htmlFontSize = "6"; // Corresponds to HTML font size value for 18px
        break;
      case "20":
        htmlFontSize = "7"; // Corresponds to HTML font size value for 20px
        break;
      default:
        htmlFontSize = "4"; // Default to medium size if not matched
    }

    document.execCommand("fontSize", false, htmlFontSize);
  };

  const getSelectedFontSize = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const parentElement = range.commonAncestorContainer.parentElement;
      setSelectedFontSize(parentElement.style.fontSize || "12");
    }
  };

  useEffect(() => {
    document.addEventListener("selectionchange", getSelectedFontSize);
    return () => {
      document.removeEventListener("selectionchange", getSelectedFontSize);
    };
  }, []);

  useEffect(() => {
    const setHeight = () => {
      const editorWrapper = document.querySelector(".contaianerNotepad");
      const editor = document.querySelector(".editor");
      if (editorWrapper && editor) {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        editorWrapper.style.height = `${windowHeight}px`;
        editor.style.height = `${windowHeight}px`;
        editor.style.overflow = "auto";
      }
    };

    setHeight(); // Initial setting

    const handleResize = () => {
      setHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const convertToUpperCase = () => {
    const lines = editorRef.current.innerText.split("\n");
    editorRef.current.innerHTML = lines
      .map((line) => line.toUpperCase())
      .join("<br>");
    saveState();
  };

  const convertToLowerCase = () => {
    const lines = editorRef.current.innerText.split("\n");
    editorRef.current.innerHTML = lines
      .map((line) => line.toLowerCase())
      .join("<br>");
    saveState();
  };

  const convertToCamelCase = () => {
    const lines = editorRef.current.innerText.split("\n");
    editorRef.current.innerHTML = lines
      .map((line) => toProperCase(line))
      .join("<br>");
    saveState();
  };

  const toProperCase = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase());
  };

  const isMobile = window.innerWidth <= 650;

  return (
    <div className="notepad_wrapper">
      <div className="container-lg p-0 contaianerNotepad paddingAfter420 d-flex">
        <div className="row h-100">
          <div className="col-md-1">
            {/* <AdComponent
              slot="4982546863"
              format="auto"
              fullWidthResponsive="true"
            /> */}
          </div>
          <div className="col-md-10 py-2 px-0">
            <div className="notepad mx-auto my-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem 5px",
                }}
              >
                <h1 className="logoHead" style={{ width: "fit-content" }}>
                  <img src={Logo} alt="Chat GPT notepad" title="Notepad GPT" />
                  <snap className="hideAfter510">ChatGPT Notepad</snap>
                </h1>
                <div>
                  <div
                    className="tool_word showAfter530"
                    style={{ fontSize: "13px", width: "fit-content" }}
                  >
                    <b>Word Count:</b> {wordCount}
                  </div>
                  <div
                    className="tool_word showAfter530"
                    style={{ fontSize: "11px", width: "fit-content" }}
                  >
                    <b>Characters :</b> {characterCount}
                  </div>
                </div>
              </div>
              <div className="fjj hideAfter666">
                <div className="toolbar borderlessBts">
                  <button className="tool" onClick={clearEditor}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-file-earmark"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    </svg>
                    <span className="icon-label ">New</span>
                  </button>
                  <button className="tool" onClick={saveToFile}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-save"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                    <span className="icon-label ">Save</span>
                  </button>
                  <button className="tool" onClick={copyText}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    <span className="icon-label ">Copy</span>
                  </button>
                  <button className="tool" onClick={cutText}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-scissors"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                    </svg>
                    <span className="icon-label ">Cut</span>
                  </button>
                  <button className="tool" onClick={selectAll}>
                    <svg
                      xmlns="http://w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-check2-all"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                    </svg>
                    <span className="icon-label ">Select All</span>
                  </button>
                  <button className="tool" onClick={boldText}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-type-bold"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                    </svg>
                    <span style={{ fontSize: "15px" }}>Bold</span>
                  </button>
                  <button className="tool" onClick={undo}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-counterclockwise"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                    </svg>
                    <span className="icon-label">Undo</span>
                  </button>
                  <button className="tool" onClick={redo}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    <span className="icon-label ">Redo</span>
                  </button>
                  <button className="tool" onClick={toggleFullScreen}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrows-fullscreen"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
                    </svg>
                    <span className="icon-label ">Fullscreen</span>
                  </button>
                  <div
                    className="tool hideAfter530"
                    style={{ fontSize: "15px" }}
                  >
                    Word Count: {wordCount}
                  </div>
                  <div className="casesBtns pr-0">
                    <select value={selectedFont} onChange={handleFontChange}>
                      <option value="Arial">Arial</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Courier New">Courier New</option>
                    </select>
                    <select
                      value={selectedFontSize}
                      onChange={handleFontSizeChange}
                    >
                      <option value="12">12</option>
                      <option value="14">14</option>
                      <option value="16">16</option>
                      <option value="18">18</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="casesBtns">
                    <button onClick={convertToUpperCase}>Upper Case</button>
                    <button onClick={convertToLowerCase}>Lower Case</button>
                    <button onClick={convertToCamelCase}>Camel Case</button>
                  </div>
                </div>
              </div>
              <div
                id="editor"
                ref={editorRef}
                contentEditable="true"
                className="editor txtara"
                onPaste={handlePaste}
                onInput={saveState}
                style={{
                  border: "2px solid",
                  borderRadius: "5px",
                  padding: "1rem 0.5rem",
                  overflow: "auto",
                  minHeight: "300px",
                  fontFamily: selectedFont,
                }}
                placeholder={
                  isMobile
                    ? "Long press to paste or type text here..."
                    : "Paste or type something in here.."
                }
              ></div>
              <div className="fjj fjj2 showAfter666">
                <div className="toolbar borderlessBts">
                  <button className="tool" onClick={boldText}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-type-bold"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                    </svg>
                    <span style={{ fontSize: "15px" }}>Bold</span>
                  </button>
                  <button className="tool" onClick={copyText}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    <span className="icon-label ">Copy</span>
                  </button>
                  <button className="tool" onClick={cutText}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-scissors"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                    </svg>
                    <span className="icon-label ">Cut</span>
                  </button>
                  <button className="tool" onClick={selectAll}>
                    <svg
                      xmlns="http://w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-check2-all"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                    </svg>
                    <span className="icon-label ">Select All</span>
                  </button>
                  <button className="tool" onClick={undo}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-counterclockwise"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                    </svg>
                    <span className="icon-label">Undo</span>
                  </button>

                  <button className="tool" onClick={redo}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    <span className="icon-label ">Redo</span>
                  </button>
                  <button className="tool" onClick={saveToFile}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-save"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                    <span className="icon-label ">Save</span>
                  </button>
                  <button className="tool" onClick={clearEditor}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-file-earmark"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    </svg>
                    <span className="icon-label ">New</span>
                  </button>
                  <button className="tool" onClick={toggleFullScreen}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrows-fullscreen"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
                    </svg>
                    <span className="icon-label ">Fullscreen</span>
                  </button>
                  <div
                    className="tool hideAfter530"
                    style={{ fontSize: "15px" }}
                  >
                    Word Count: {wordCount}
                  </div>
                  <div className="casesBtns pr-0 hideAfter666">
                    <select value={selectedFont} onChange={handleFontChange}>
                      <option value="Arial">Arial</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Courier New">Courier New</option>
                    </select>
                    <select
                      value={selectedFontSize}
                      onChange={handleFontSizeChange}
                    >
                      <option value="12">12</option>
                      <option value="14">14</option>
                      <option value="16">16</option>
                      <option value="18">18</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="casesBtns">
                    <button onClick={convertToUpperCase}>Upper Case</button>
                    <button onClick={convertToLowerCase}>Lower Case</button>
                    <button onClick={convertToCamelCase}>Camel Case</button>
                  </div>
                </div>
              </div>
              <div className="policyViewBtns">
                <p>
                  Powered by{" "}
                  <a href="https://www.triosoft.ai/">
                    <span className="fontWeights">Triosoft Technologies</span>
                  </a>{" "}
                  |&nbsp;
                </p>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#privacyModal"
                >
                  Privacy Policy
                </button>
                <p>&nbsp;|&nbsp;</p>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#termsModal"
                >
                  Terms & Conditions
                </button>
                <p>&nbsp;|&nbsp;</p>
                <Link to="/Blog">
                  <button type="button">Blog</button>
                </Link>
                <p>&nbsp;|&nbsp;</p>
                <Link to="/AboutUs">
                  <button type="button">About Us</button>
                </Link>
                <p>&nbsp;|&nbsp;</p>
                <Link to="/ContactUs">
                  <button type="button">Contact Us</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-1">
            {" "}
            {/* <AdComponent
              slot="6948017615"
              format="autorelaxed"
              fullWidthResponsive="false"
            /> */}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="privacyModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="exampleModalLabel">
                Privacy Policy
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <PrivacyPolicy />
            </div>
            <div className="modal-footer" hidden>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="termsModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="exampleModalLabel">
                Terms and Conditions of Use
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <TermsOfUse />
            </div>
            <div className="modal-footer" hidden>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotepadNew;
