import React, { useEffect } from 'react';

const AdComponent = ({ slot, format, fullWidthResponsive }) => {
  useEffect(() => {
    // if (window && window.adsbygoogle) {
    //   window.adsbygoogle.push({}); // Call push() once for all ads on the page
    // }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-3683864409644891"
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={fullWidthResponsive}
    ></ins>
  );
};

export default AdComponent;