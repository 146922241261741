import React from "react";
import Header from "./Header";
import "./css/AboutUs.css";

const Shortcut = () => {
  const shortcuts = [
    { action: "Open file", pc: "Ctrl + O", mac: "Command + O" },
    { action: "Save", pc: "Ctrl + S", mac: "Command + S" },
    {
      action: "Download file",
      pc: "Ctrl + Shift + S",
      mac: "Command + Shift + S",
    },
    { action: "Print", pc: "Ctrl + P", mac: "Command + P" },
    { action: "Undo", pc: "Ctrl + Z", mac: "Command + Z" },
    { action: "Redo", pc: "Ctrl + Y", mac: "Command + Y" },
    { action: "Cut selected text", pc: "Ctrl + X", mac: "Command + X" },
    { action: "Copy selected text", pc: "Ctrl + C", mac: "Command + C" },
    { action: "Highlight all", pc: "Ctrl + A", mac: "Command + A" },
    {
      action: "Find and replace",
      pc: "Ctrl + Shift + R",
      mac: "Command + Shift + R",
    },
    {
      action: "Insert date & time",
      pc: "Ctrl + Shift + D",
      mac: "Command + Shift + D",
    },
    {
      action: "Open charmap",
      pc: "Ctrl + Shift + C",
      mac: "Command + Shift + C",
    },
    {
      action: "Open emoji list",
      pc: "Ctrl + Shift + E",
      mac: "Command + Shift + E",
    },
    {
      action: "Open font preference",
      pc: "Ctrl + Shift + G",
      mac: "Command + Shift + G",
    },
    {
      action: "Toggle fullscreen",
      pc: "Ctrl + Shift + F",
      mac: "Command + Shift + F",
    },
  ];

  return (
    <div id="body">
      <div className="App">
        <header id="navbar">
          <Header />
        </header>
        <section id="about">
          <h1 hidden>Keyboard Shortcuts in Chat GPT Notepad</h1>
          <h2>Keyboard Shortcuts in Chat GPT Notepad</h2>
        </section>

        <div className="container">
          <section id="what-is">
            <div className="what-is-chatgpt">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <h3>Shortcuts</h3>
                  <p>
                    Here is the complete list of key combinations that will help
                    you to speed things up when using Online Notepad's editor.
                  </p>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>PC</th>
                          <th>Mac</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shortcuts.map((shortcut, index) => (
                          <tr key={index}>
                            <td>{shortcut.action}</td>
                            <td>{shortcut.pc}</td>
                            <td>{shortcut.mac}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Shortcut;
